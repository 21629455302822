import React, { useEffect, useMemo, useState } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";

interface TabItem {
  href: string;
  label: string;
}

const TopTabsMenu: React.FC = () => {
  const location = useLocation();

  const [activeTabItem, setActiveItem] = useState<TabItem | null>(null);

  const tabs: TabItem[] = useMemo(
    () => [
      {
        href: `/visits`,
        label: "Visitors",
      },
      {
        href: `/visits/create`,
        label: "Create Visit",
      },
    ],
    []
  );

  useEffect(() => {
    tabs.forEach((item) => {
      const active = !!matchPath({ path: item.href, end: true }, location.pathname);

      if (active) {
        setActiveItem(item);
      }
    });
  }, [location.pathname, tabs]);

  return (
    <Box
      sx={{
        pt: 8,
      }}
    >
      <Outlet />
    </Box>
  );
};

export default TopTabsMenu;
