import { useEffect, useState } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box, Button, CssBaseline, ThemeProvider, Typography } from "@material-ui/core";
import { initializeI18n, setLanguage } from "./i18n";

import { useSettings } from "./contexts/settings-context";

import routes from "./routes";
import { createCustomTheme } from "./theme";
import useStore from "./hooks/useStore";
import Loader from "./components/atoms/Loader/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import receptionServiceApi from "./api/clients/receptionServiceApi";

import { MachineTokenDialog } from "./components/molecules/Settings/MachineTokenDialog";

import { observer } from "mobx-react-lite";
import toast from "react-hot-toast";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-GB";
import { useDialog } from "./hooks/use-dialog";

import { TurnOnReceptionAppDeskDialog } from "./components/molecules/Settings/TurnOnReceptionAppDeskDialog";
import { useTranslation } from "react-i18next";

initializeI18n();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  const { t } = useTranslation();

  const { isAuthenticated, user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();

  const [isLoadingToken, setIsLoadingToken] = useState(false);

  // MACHINE TOKEN HANDLERS

  const [apiError, setApiError] = useState<string | null>(null);

  const [
    isOpenInformationAboutReceptionAppDialog,
    handleOpenInformationAboutReceptionAppDialog,
    handleCloseInformationAboutReceptionAppDialog,
  ] = useDialog();

  const handleGetMachineToken = async () => {
    setApiError(null);

    try {
      const { data } = await receptionServiceApi.get("/machine-token");

      if (data.machineToken) {
        store.machine.setToken(data.machineToken);
      } else {
        store.machine.setIsOpenDialog(true);
      }
    } catch (error) {
      console.log({ error });

      if (!error.response) {
        handleOpenInformationAboutReceptionAppDialog();
        setApiError(t("receptionAgent.cannotConnect"));
        toast.error(t("receptionAgent.cannotConnect"));
      }

      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        toast.error(error?.response?.data?.message ?? "Error");

        if (error.response.status === 404) {
          store.machine.setIsOpenDialog(true);
        }
      }
    }
  };

  const getToken = async () => {
    try {
      setIsLoadingToken(true);
      const token = await getAccessTokenSilently();
      store.user.setToken(token);
      // getSites();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  const store = useStore();

  useEffect(() => {
    setLanguage(settings.language);
  }, [settings]);

  useEffect(() => {
    if (isAuthenticated) {
      store.user.setUserOrganizationId(user["https://platform.signalos.io/active_org_id"]);
      getToken();
      handleGetMachineToken();
    }
  }, [isAuthenticated, user]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  const renderContent = () => {
    if (isLoading || isLoadingToken) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Loader />
        </Box>
      );
    }

    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography>Oops... {error.message}</Typography> <br />
          <Button variant="contained" onClick={() => logout({ returnTo: window.location.origin })}>
            Logout
          </Button>
        </Box>
      );
    } else {
      return content;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={settings.language === "pl" ? pl : en}
      >
        <CssBaseline />
        {renderContent()}
        <MachineTokenDialog
          open={store.machine.isOpenDialog}
          title={t("receptionAgent.setMachineToken")}
          onConfirm={() => {
            handleGetMachineToken();
            store.machine.setIsOpenDialog(false);
          }}
          variant="info"
        />
        <TurnOnReceptionAppDeskDialog
          open={isOpenInformationAboutReceptionAppDialog}
          onCancel={handleCloseInformationAboutReceptionAppDialog}
          onConfirm={() => {
            handleGetMachineToken();
            handleCloseInformationAboutReceptionAppDialog();
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default observer(App);
