import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@material-ui/core";

import { SettingsNavbar } from "../../components/organisms/SettingsNavbar";
import { Footer } from "../../components/molecules/Footer";

export const SettingsLayout: FC = () => {
  const LayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  }));

  const LayoutContent = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }));

  return (
    <LayoutRoot>
      <SettingsNavbar />
      <Box
        sx={{
          pt: 8,
        }}
      >
        <LayoutContent>
          <Outlet />
          <Footer />
        </LayoutContent>
      </Box>
    </LayoutRoot>
  );
};
