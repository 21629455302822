import store from "../../store/ApplicationStore";
import { AxiosRequestConfig, AxiosResponse } from "axios";

// const routerHistory = useHistory();

const errorHandlers = {
  401: (error: any) => {
    // store.auth.clearToken();

    // if (routerHistory.location.pathname !== '/sign-in') {
    //   routerHistory.push(`/sign-in`);
    // }

    throw error;
  },
};

const onRequestInterceptor = (config: AxiosRequestConfig) => {
  const { user, machine } = store;
  const { token } = user;
  if (!token) return config;
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["s-organization-token"] = user.userOrganizationId;
  config.headers["s-device-token"] = machine.machineToken;

  return config;
};

const onResponseInterceptor = (response: AxiosResponse) => {
  return response;
};

const onErrorInterceptor = (error: any) => {
  const errorHandler = errorHandlers[error?.response?.status];

  if (errorHandler) {
    return errorHandler(error);
  }

  throw error;
};

export { onRequestInterceptor, onResponseInterceptor, onErrorInterceptor };
