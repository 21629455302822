import { useState } from "react";
import type { FC } from "react";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { AccountPopover } from "../../components/organisms/AccountPopover";
import { AppDialog } from "../../components/organisms/AppDialog";

import { useSettings } from "../../contexts/settings-context";

import { Moon as MoonIcon } from "../../icons/moon";
import { Sun as SunIcon } from "../../icons/sun";

import { Apps, ArrowBack } from "@material-ui/icons";

import { useDialog } from "../../hooks/use-dialog";

import { useNavigate } from "react-router-dom";

const organizations = [
  {
    id: "6039124832",
    name: "SignalOS",
  },
  {
    id: "3828312374",
    name: "Warta",
  },
  {
    id: "123123",
    name: "Google",
  },
];

export const SettingsNavbar: FC = () => {
  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();

  const [darkMode, setDarkMode] = useState(settings.theme === "dark");
  const [currentOrganization, setCurrentOrganization] = useState(organizations[0]);
  const [appDialog, handleOpenAppDialog, handleCloseAppDialog] = useDialog();
  const navigate = useNavigate();

  const handleLanguageChange = (language: "en" | "pl"): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
    toast.success(t("Language changed"));
  };

  const handleSwitchTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === "light" ? "dark" : "light",
    });

    setDarkMode(settings.theme === "light");
  };

  const handleOrganizationChange = (organizationId: string): void => {
    const newOrganization = organizations.find(
      (organization) => organization.id === organizationId
    );

    if (!newOrganization) {
      return;
    }

    setCurrentOrganization(newOrganization);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <AppBar elevation={0} sx={{ backgroundColor: "#1e212a" }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: "center",
          display: "flex",
          minHeight: 64,

          pr: 2,
          py: 1,
        }}
      >
        <Box
          // component={RouterLink}
          // to="/"
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "72px",
            transition: "0.3s",
            position: "relative",
            "&:hover .apps": {
              visibility: "visible",
              opacity: 1,
            },
          }}
        >
          <Box
            className="logo"
            sx={{ transition: "0.3s", height: "100%", display: "flex", alignItems: "center" }}
          >
            <IconButton aria-label="go back" color="inherit" size="large" onClick={handleGoBack}>
              <ArrowBack />
            </IconButton>
          </Box>
        </Box>
        <Box>Settings</Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleOpenAppDialog}
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        >
          {<Apps />}
        </IconButton>
        <AccountPopover
          currentOrganization={currentOrganization}
          darkMode={darkMode}
          onLanguageChange={handleLanguageChange}
          onOrganizationChange={handleOrganizationChange}
          onSwitchTheme={handleSwitchTheme}
          organizations={organizations}
        />
        <AppDialog open={appDialog} onClose={handleCloseAppDialog} />
      </Toolbar>
    </AppBar>
  );
};
