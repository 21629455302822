import { makeAutoObservable, runInAction } from "mobx";

import { Destination } from "../types/organization/visits";

import { invitorApi } from "../api/organization-admin/invitor";

class VisitsStore {
  constructor() {
    makeAutoObservable(this);
  }

  destinations: Destination[] = [];

  fetchDestinations = async () => {
    const results = await invitorApi.getDestinations();

    runInAction(() => {
      this.destinations = results;
    });
  };
}

export default VisitsStore;
