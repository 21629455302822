import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";

import { SettingsProvider } from "./contexts/settings-context";
import App from "./app";
import ApplicationStoreProvider from "./contexts/ApplicationStoreContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApplicationStoreProvider>
          <SettingsProvider>
            <Auth0Provider
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              redirectUri={window.location.origin}
              cacheLocation="localstorage"
              audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            >
              <QueryClientProvider client={queryClient}>
                <App />
                <Toaster position="bottom-right" />
              </QueryClientProvider>
            </Auth0Provider>
          </SettingsProvider>
        </ApplicationStoreProvider>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
