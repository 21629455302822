import type { FC } from "react";

import {
  Button,
  Dialog as DialogCore,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { useTranslation } from "react-i18next";

interface TurnOnReceptionAppDeskDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const TurnOnReceptionAppDeskDialog: FC<TurnOnReceptionAppDeskDialogProps> = (props) => {
  const { onCancel, onConfirm, open, ...other } = props;
  const { t } = useTranslation();

  return (
    <DialogCore
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <ErrorIcon color="error" fontSize="large" />
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {t("common.appReceptionIsTurnedOff")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {t("common.receptionDeskAgentIsRequired")}
        <br />
        <br /> {t("common.turnItOn")}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onConfirm} variant="contained">
          {t("common.tryAgain")}
        </Button>
      </DialogActions>
    </DialogCore>
  );
};
