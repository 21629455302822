import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        noResults: "There is no results",
        logout: "Log out",
        error: "Something went wrong",
        perPage: "Per page:",
        page: "Strona",
        of: "z",
        printing: "Printing...",
        notAdded: "Not added",
        appReceptionIsTurnedOff: "Reception Desk Agent is turned off!",
        receptionDeskAgentIsRequired:
          "Reception Desk Agent is required for Reception Desk to work properly.",
        turnItOn: "Turn it on and click try again.",
        tryAgain: "Try again",
        yes: "Yes",
        no: "No",
        cancel: "Cancel",
        import: "Import",
        downloadTemplate: "Download template",
        dragNDrop: "Drag 'n' drop some file here, or click to select the file",
        browse: "Browse",
        dropFile: "or drop file here",
        add: "Add",
        bulkActions: "Bulk actions",
        maxFilesNumberError: "Number of files exceeded the {{limit}} files limit",
        languages: {
          pl: "Polish",
          en: "English",
        },
        confirm: "Confirm",
      },
      receptionAgent: {
        cannotConnect:
          "Could not connect to Reception Panel Service. Make sure Service is running.",
        setMachineToken: "Set machine token",
      },
      visitors: {
        title: "Visitors",
        tab: "Visitors | SignalOS",
        create: "Create visit",
        filters: {
          destination: "Destination",
          createdFrom: "Created from",
          expectedFrom: "Expected from",
          finishesFrom: "Finishes from",
          to: "to",
          status: "Status",
          search: "Search",
          moreFilters: "More filters",
          createdTo: "Created to",
          expectedTo: "Expected to",
          finishesTo: "Finishes to",
          resetAll: "Reset all filters",
          created: "Created",
          cancelled: "Cancelled",
          category: "Category",
          started: "Started",
          finished: "Finished",
        },
        table: {
          guest: "Guest",
          host: "Host",
          destination: "Destination",
          createdAt: "Created at",
          start: "Start",
          end: "End",
          category: "Category",
          finish: "Finish",
          cancel: "Cancel",
          floor: "Floor",
        },
        dialogs: {
          areYouSureFinish: "Are you sure you want to finish this visit?",
          areYouSureCancel: "Are you sure you want to cancel this visit?",
          areYouSure: "Are You sure?",
          importXls: {
            title: "Import xsl",
            description:
              'Import your list of visitors below. Click the "Download template" button to get the .xsl template, fill in all the necessary information, and upload it.',
            dropXls: "or drop .xls file to upload",
            incorrectFile: "Incorrect file type",
            success: "Successfully imported visitors list",
          },
        },
      },
      createExpressQrPrinting: {
        title: "Express QR Printing",
        tab: "Express QR Printing | SignalOS",
        chooseDestination: "Choose destination",
        datesInfo: "Dates info",
        summary: "Confirmation",
        howToEndTheVisit: "How to end the visit",
        leavingTheBuilding: "Leaving the building",
        thereWasAnErrorWhilePrinting: "Error while printing QR codes",
      },
      create: {
        title: "Create visit",
        tab: "Create visit | SignalOS",
        visitorInfoDetailsTitle: "Added guests",
        discount: "Discount",
        back: "Back",
        nextStep: "Next step",
        addAnotherUser: "Add another guest",
        addGuest: "Add guest",
        step: "Step {{step}}",
        visitorDetails: "Visitors",
        datesInfo: "Dates",
        destinationInfo: "Destinations",
        hostInfo: "Host",
        category: "Category",
        invitedGuests: "Invited guests",
        attachments: "Attachments",
        additionalInfo: "Additional information",
        confirmation: "Confirmation",
        firstName: "First name",
        lastName: "Last name",
        expectedAt: "Expected at",
        finishesAt: "Finishes at",
        browseOrDropFile: "Browse or drop file(s) to upload",
        eachFileShouldNotExceed:
          "Each file should not exceed 15 MB in size. Supported formats: .pdf, .jpeg, .png.",
        chooseDestination: "Choose destination",
        chooseHost: "Choose host",
        isGuestHandicapped: "Is guest handicapped?",
        isGuestHelper: "Check if the guest is handicapped.",
        isGuestPregnant: "Is guest pregnant?",
        isGuestPregnantHelper: "Check if the guest is pregnant.",
        isGuestComingWithPet: "Is guest coming with pet?",
        isGuestComingWithPetHelper: "Check if the guest is coming with pet.",
        guestHasAccessToParking: "Should the guest have access to the parking?",
        guestHasAccessToParkingHelper: "Check if the guest should have access to the parking.",
        hasAccessToParkingTooltip: "Your host can't invite to parking",
        requiredField: "This field is required",
        destination: "destination",
        destinations: "destinations",
        success: "Visit created",
        yes: "Yes",
        no: "No",
        error: "Error",
        confirm: "Confirm",
        description: "Additional notes",
        hostsError: "Couldn't fetch hosts",
        searchPlaceholder: "Type first name and last name",
        searchInfo: "Type min. 3 letters to start searching host",
        datesError: "Finishes date must be later than expected date",
        columns: {
          name: "First name Last name",
          handicapped: "Handicapped",
          pregnant: "Pregnant",
          parkingAccess: "Parking",
          pet: "Pet",
          language: "Language",
          description: "Description",
        },
        language: "Language",
        remove: "Remove",
      },
      visit: {
        back: "Visits",
        info: {
          title: "Visitors Information",
          guest: "Guest",
          status: "Status",
          isHandicapped: "Is handicapped",
          isPregnant: "Is pregnant",
          publicParking: "PUBLIC PARKING - LICENSE PLATES",
          printQr: "Print QR",
          pay: "Pay for the parking ticket",
          description: "Additional notes",
          language: "Language",
        },
        destination: {
          title: "Destination info",
          manyDestinationsTitle: "Destinations info",
          name: "Name",
          floor: "Floor",
          directionTip: "Direction tip",
          description: "Description",
        },
        attachments: {
          title: "Attachments",
          name: "Name",
          downloadSelected: "Download selected",
          deleteSelected: "Delete selected",
          dialog: {
            add: {
              title: "Add attachment",
              browseOrDropFile: "Browse or drop file(s) to upload",
              eachFileShouldNotExceed:
                "Each file should not exceed 15 MB in size. Supported formats: .pdf, .jpeg, .png.",
              success: "Successfully added attachment(s)",
              warning: "Adding some attachments failed",
              failed: "Failed to add attachment(s)",
            },
            delete: {
              title: "Delete attachment",
              description:
                "Are you sure you want to delete this attachment(s)? This can't be undone.",
              deleteAttachment: "Delete attachment",
              success: "Successfully deleted attachment(s)",
              warning: "Deleting some attachments failed",
              failed: "Failed to delete attachment(s)",
            },
          },
        },
        reason: {
          title: "Reason of the visit",
          category: "Category",
        },
        host: {
          title: "Host info",
          phone: "Phone number",
          canInviteToParking: "Can invite to parking",
          yes: "Yes",
          no: "No",
        },
        status: {
          success: "Status changed",
          error: "Something went wrong",
          title: "Status",
          save: "Save Changes",
        },
        hours: {
          title: "Hours",
          createdAt: "Created at",
          expectedAt: "Expected at",
          arrivedAt: "Arrived at",
          finishesAt: "Finishes at",
        },
        parking: {
          success: "Ticket payed successfully",
          title: "Pay for the parking ticket",
          ticketNumber: "Ticket number",
          cancel: "Cancel",
          submit: "Submit ticket number",
          pay: "Pay for the parking ticket",
          required: "Ticket number is required",
        },
        finish: {
          title: "Visit finish reason",
          manual: "Manually finished",
          automatic_visitors_left_building: "Visitors left the building",
          automatic_time_finished: "Visiting time is over",
        },
        extendVisitDialog: {
          title: "Extend visit",
          date: "New finish date",
          extendSuccess: "Visit extended successfully",
          prolongError: "Visit extension in progress",
        },
        printQrForSelected: "Print QR for selected visitors",
        printMassQRDialog: {
          title: "Print QR for selected visitors",
          message: "Do you want to print Tickets for selected visit guests?",
        },
      },
    },
  },
  pl: {
    translation: {
      common: {
        noResults: "Niestety brak wyników",
        logout: "Wyloguj",
        error: "Coś poszło nie tak",
        perPage: "Na stronę:",
        page: "Page",
        of: "of",
        printing: "Drukowanie...",
        maxFilesNumberError: "Przekroczono limit plików {{limit}}",
        dragNDrop: "Przeciągnij swój plik tutaj, lub kliknij i wybierz",
        import: "Importuj",
        downloadTemplate: "Pobierz wzór",
        notAdded: "Nie dodana",
        appReceptionIsTurnedOff: "Aplikacja Reception Desk Agent jest wyłączona!",
        receptionDeskAgentIsRequired:
          "Do prawidłowego działania panelu recepcji wymagana jest włączona aplikacja Agent Recepcji Desk.",
        turnItOn: "Włącz ją i kliknij spróbuj ponownie.",
        tryAgain: "Spróbuj ponownie",
        browse: "Przeglądaj",
        dropFile: "lub upuść plik tutaj",
        yes: "Tak",
        no: "Nie",
        cancel: "Anuluj",
        add: "Dodaj",
        bulkActions: "Działania masowe",
        languages: {
          pl: "Polski",
          en: "Angielski",
        },
        confirm: "Potwierdź",
      },
      receptionAgent: {
        cannotConnect:
          "Nie udało się połączyć do Agenta Panelu Recepcji. Upewnij się że Agent jest uruchomiony.",
        setMachineToken: "Ustaw token komputera",
      },
      visitors: {
        title: "Goście",
        tab: "Goście | SignalOS",
        create: "Stwórz wizytę",
        filters: {
          destination: "Destynacje",
          createdFrom: "Stworzone od",
          expectedFrom: "Oczekiwany od",
          finishesFrom: "Zakończona od",
          to: "do",
          status: "Status",
          category: "Kategoria",
          search: "Szukaj",
          moreFilters: "Więcej filtrów",
          createdTo: "Stworzone do",
          expectedTo: "Oczekiwane do",
          finishesTo: "Zakończona do",
          resetAll: "Zresetuj wszystkie filtry",
          created: "Utworzona",
          cancelled: "Anulowana",
          started: "Rozpoczęta",
          finished: "Zakończona",
        },
        table: {
          guest: "Gość",
          host: "Gospodarz",
          destination: "Destynacja",
          createdAt: "Utworzona",
          category: "Kategoria",
          start: "Start",
          end: "Koniec",
          finish: "Zakończ",
          cancel: "Anuluj",
          floor: "Piętro",
        },
        dialogs: {
          areYouSureFinish: "Czy na pewno chcesz zakończyć tą wizytę?",
          areYouSureCancel: "Czy na pewno chcesz anulować tą wizytę?",
          areYouSure: "Czy na pewno?",
          importXls: {
            title: "Importuj plik XLS",
            description:
              'Zaimportuj listę gości poniżej. Kliknij przycisk "Pobierz szablon", aby pobrać szablon .xsl, wypełnij wszystkie niezbędne informacje i prześlij go.',
            dropXls: "Upuść plik XLS tutaj",
            incorrectFile: "Niepoprawny typ pliku",
            success: "Lista gości zaimportowana pomyślnie",
          },
        },
      },
      createExpressQrPrinting: {
        title: "Ekspresowe drukowanie kodów QR",
        tab: "Ekspresowe drukowanie kodów QR | SignalOS",
        chooseDestination: "Destynacje",
        datesInfo: "Daty",
        summary: "Potwierdzenie",
        howToEndTheVisit: "Jak zakończyć wizytę",
        leavingTheBuilding: "Opuszczając budynek",
        thereWasAnErrorWhilePrinting: "Błąd podczas drukowania kodów QR",
      },
      create: {
        title: "Stwórz wizytę",
        tab: "Stwórz wizytę | SignalOS",
        visitorInfoDetailsTitle: "Dodani goście",
        discount: "Zniżka",
        back: "Wróć",
        category: "Kategoria",
        invitedGuests: "Zaproszeni goście",
        attachments: "Załączniki",
        nextStep: "Kolejny krok",
        addAnotherUser: "Dodaj kolejnego gościa",
        addGuest: "Dodaj gościa",
        step: "Krok {{step}}",
        visitorDetails: "Goście",
        datesInfo: "Daty",
        destinationInfo: "Destynacje",
        hostInfo: "Gospodarz",
        additionalInfo: "Dodatkowe informacje",
        confirmation: "Potwierdzenie",
        firstName: "Imię",
        lastName: "Nazwisko",
        expectedAt: "Rozpocznie się o",
        finishesAt: "Zakończy się o",
        browseOrDropFile: "Przeglądaj lub upuść plik(i) do przesłania",
        eachFileShouldNotExceed:
          "Żaden plik nie powinien przekraczać 15 MB. Obsługiwane formaty: .pdf, .jpeg, .png.",
        chooseDestination: "Wybierz destynacje",
        chooseHost: "Wybierz gospodarza",
        isGuestHandicapped: "Czy gość jest niepełnosprawny?",
        isGuestHelper: "Zaznacz jeżeli gość jest niepełnosprawny.",
        isGuestPregnant: "Czy gość jest w ciąży?",
        isGuestPregnantHelper: "Zaznacz jeżeli gość jest w ciąży.",
        isGuestComingWithPet: "Czy gość przyszedł z zwierzęciem?",
        isGuestComingWithPetHelper: "Zaznacz jeżeli gość przyszedł z zwierzęciem.",
        guestHasAccessToParking: "Czy gość ma mieć dostęp do parkingu?",
        guestHasAccessToParkingHelper: "Zaznacz jeżeli gość ma mieć dostęp do parkingu.",
        hasAccessToParkingTooltip: "Twój gospodarz nie może zapraszać na parking",
        requiredField: "To pole jest wymagane",
        destination: "destynacja",
        destinations: "destynacje",
        success: "Wizyta utworzona",
        yes: "Tak",
        no: "Nie",
        error: "Błąd",
        confirm: "Potwierdź",
        description: "Dodatkowa informacja",
        hostsError: "Nie udało się pobrać listy gospodarzy",
        searchPlaceholder: "Wpisz imię i nazwisko",
        searchInfo: "Wprowadź min. 3 znaki by rozpocząć wyszukiwanie gospodarza",
        datesError: "Data zakończenia musi być późniejsza niż data rozpoczęcia",
        columns: {
          name: "Imię i nazwisko",
          handicapped: "Niepełnosprawność",
          pregnant: "Ciąża",
          parkingAccess: "Parking",
          pet: "Zwierze",
          language: "Język",
          description: "Informacje",
        },
        language: "Język",
        remove: "Usuń",
      },
      visit: {
        back: "Wizyty",
        info: {
          title: "Informacje o gościach",
          guest: "Gość",
          status: "Status",
          isHandicapped: "Niepełnosprawny",
          isPregnant: "W ciąży",
          publicParking: "Parking publiczny - numery rejestracyjne",
          printQr: "Wydrukuj kod QR",
          pay: "Opłać bilet parkingowy",
          description: "Dodatkowa informacja",
          language: "Język",
        },
        destination: {
          title: "Informacje o destynacji",
          manyDestinationsTitle: "Informacje o destynacjach",
          name: "Nazwa",
          floor: "Piętro",
          directionTip: "Wskazówki dotarcia",
          description: "Opis",
        },
        reason: {
          title: "Powód wizyty",
          category: "Kategoria",
        },
        host: {
          title: "Gospodarz",
          phone: "Numer telefonu",
          canInviteToParking: "Może zapraszać na parking",
          yes: "Tak",
          no: "Nie",
        },
        status: {
          success: "Status zmieniony",
          error: "Coś poszło nie tak",
          title: "Status",
          save: "Zapisz zmiany",
        },
        attachments: {
          title: "Załączniki",
          name: "Nazwa",
          downloadSelected: "Pobierz zaznaczone",
          deleteSelected: "Usuń zaznaczone",
          dialog: {
            add: {
              title: "Dodaj załącznik",
              browseOrDropFile: "Przeglądaj lub upuść plik(i) do przesłania",
              eachFileShouldNotExceed:
                "Żaden plik nie powinien przekraczać 15 MB. Obsługiwane formaty: .pdf, .jpeg, .png.",
              success: "Pomyślnie dodano załącznik(i)",
              warning: "Nie udało się dodać niektórych załączników",
              failed: "Nie udało się dodać załącznika(ów)",
            },
            delete: {
              title: "Usuń załącznik",
              description:
                "Czy na pewno chcesz usunąć ten załącznik(iki)? Tej operacji nie można cofnąć.",
              deleteAttachment: "Usuń załącznik",
              success: "Pomyślnie usunięto załącznik(i)",
              warning: "Nie udało się usunąć niektórych załączników",
              failed: "Nie udało się usunąć załącznika(ów)",
            },
          },
        },
        hours: {
          title: "Godziny",
          createdAt: "Utworzona o",
          expectedAt: "Oczekiwany o",
          arrivedAt: "Przybył o",
          finishesAt: "Zakończyła się o",
        },
        parking: {
          success: "Bilet opłacony poprawnie",
          title: "Zapłać za bilet parkingowy",
          ticketNumber: "Numer biletu",
          cancel: "Anuluj",
          submit: "Przypisz bilet parkingowy",
          pay: "Opłać przypisany bilet",
          required: "Bilet jest wymagany",
        },
        finish: {
          title: "Powód zakończenia wizyty",
          manual: "Manualnie zakończona",
          automatic_visitors_left_building: "Goście opuścili budynek",
          automatic_time_finished: "Czas wizyty upłynął",
        },
        extendVisitDialog: {
          title: "Przedłuż wizytę",
          date: "Nowy koniec wizyty",
          extendSuccess: "Wizyta przedłużona pomyślnie",
          prolongError: "Trwa przedłużanie wizyty",
        },
        printQrForSelected: "Wydrukuj kod QR dla wybranych gości",
        printMassQRDialog: {
          title: "Wydrukuj kod QR dla wybranych gości",
          message: "Czy chcesz wydrukować bilety dla wybranych gości?",
        },
      },
    },
  },
};

export const initializeI18n = (): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};

export const setLanguage = (language: string) => {
  i18n.changeLanguage(language);
};
