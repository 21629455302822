import { FC } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@material-ui/core";

import { Navbar } from "../../components/organisms/Navbar";
import { Footer } from "../../components/molecules/Footer";

export const TopMenuLayout: FC = () => {
  const LayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  }));

  const LayoutContent = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }));

  return (
    <LayoutRoot>
      <Navbar />
      <LayoutContent>
        <Outlet />
        <Footer />
      </LayoutContent>
    </LayoutRoot>
  );
};
