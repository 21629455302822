import type { FC } from "react";

import {
  Button,
  Dialog as DialogCore,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import { InputField } from "../../atoms/InputField";

import { useFormik } from "formik";
import * as Yup from "yup";
import receptionServiceApi from "../../../api/clients/receptionServiceApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface MachineTokenDialogProps {
  onConfirm: () => void;
  open: boolean;
  title: string;
  variant: "error" | "warning" | "info";
}

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />,
};

export const MachineTokenDialog: FC<MachineTokenDialogProps> = (props) => {
  const { onConfirm, open, title, variant, ...other } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      machineToken: "",
      submit: null,
    },
    validationSchema: Yup.object().shape({
      machineToken: Yup.string().max(255).required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await receptionServiceApi.post("/machine-token", {
          machineToken: values.machineToken,
        });

        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        onConfirm();
      } catch (err) {
        toast.error(err.message);
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({
          submit: t("receptionAgent.cannotConnect"),
        });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <DialogCore
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {icons[variant]}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <InputField
          error={Boolean(formik.touched.machineToken && formik.errors.machineToken)}
          fullWidth
          helperText={formik.touched.machineToken && formik.errors.machineToken}
          label="Machine token"
          name="machineToken"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
          value={formik.values.machineToken}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => formik.handleSubmit()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </DialogCore>
  );
};
