import api from "../clients/invApi";

import { Attachment } from "../../types/organization/attachment";
import { Category } from "../../types/organization/categories";
import { Organization } from "../../types/organization/organization";
import { Visitor } from "../../types/organization/visitors";
import { Destination, DestinationDiscount, Visit } from "../../types/organization/visits";
import { CreateVisitPayload } from "../../types/payload/create-visit";
import cloudFunctionApi from "../clients/cloud-function";

class InvitorApi {
  async getVisits(page: number, perPage: number): Promise<Visit[]> {
    const { data } = await api.get<Visit[]>(`/visits?page=${page}&perPage=${perPage}`);

    return data;
  }

  async getVisit(visitId: number): Promise<Visit> {
    const { data } = await api.get<Visit>(`/visits/${visitId}`);

    return data;
  }

  async createVisit(values: CreateVisitPayload): Promise<Visit> {
    const { data } = await api.post<Visit>(`/visits`, values);

    return data;
  }

  async cancelVisit(visitId: number): Promise<Visit> {
    const { data } = await api.delete<Visit>(`/visits/${visitId}/cancel`);

    return data;
  }

  async finishVisit(visitId: number): Promise<Visit> {
    const { data } = await api.delete<Visit>(`/visits/${visitId}/finish`);

    return data;
  }

  async assignParkingTicket(visitId: number, ticketNumber: number): Promise<Visit> {
    const { data } = await api.post<Visit>(
      `/visitors/${visitId}/assign-parking-ticket/ticket-number`,
      {
        ticketNumber,
      }
    );

    return data;
  }

  async payParkingTicket(visitId: number): Promise<Visit> {
    const { data } = await api.post<Visit>(`/visitors/${visitId}/apply-parking-ticket-discount`);

    return data;
  }

  async getDestinations(): Promise<Destination[]> {
    const { data } = await api.get<Destination[]>(`/destinations`);

    return data;
  }

  async fetchDiscounts(destinationId: string): Promise<DestinationDiscount[]> {
    const { data } = await api.get<any>(`/discount-templates`, {
      params: {
        organizationId: destinationId,
      },
    });

    return data;
  }

  async fetchOrganization(organizationId: string): Promise<Organization> {
    const { data } = await api.get(`/organizations/${organizationId}`);

    return data;
  }

  async extendVisit(visitId: number, to: Date): Promise<Visit> {
    const { data } = await api.patch<Visit>(`/visits/${visitId}/prolong`, {
      to,
    });
    return data;
  }

  async convertSpreadsheetToVisits(file: File): Promise<Visitor[]> {
    const formData = new FormData();
    formData.append("field", file, file.name);

    const { data } = await cloudFunctionApi.post<Visitor[]>(
      `/convert-spreadsheet-to-visit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return data;
  }

  async fetchCategories(): Promise<Category[]> {
    const { data } = await api.get<Category[]>(`/visit-categories`);

    return data;
  }

  async fetchVisitAttachments(visitId: string): Promise<Attachment[]> {
    const { data } = await api.get(`/visits/${visitId}/attachments`);

    return data;
  }

  async createAttachment(visitId: string, file: File): Promise<void> {
    const formData = new FormData();

    formData.append("file", file);

    await api.post(`/visits/${visitId}/attachments`, formData);
  }

  async removeAttachment(visitId: string, attachmentId: number): Promise<void> {
    await api.delete(`/visits/${visitId}/attachments/${attachmentId}`);
  }
}

export const invitorApi = new InvitorApi();
