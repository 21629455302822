import UserStore from "./UserStore";
import VisitsStore from "./VisitsStore";
import MachineStore from "./MachineStore";

class ApplicationStore {
  visits = new VisitsStore();
  user = new UserStore();
  machine = new MachineStore();
}

export default new ApplicationStore();
