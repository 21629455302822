import axios from "axios";

const receptionServiceApi = axios.create({
  baseURL: process.env.REACT_APP_RECEPTION_ELECTRON_SERVICE,
});

receptionServiceApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      console.log("Reception Panel Service is offline.");
    }

    return Promise.reject(error);
  }
);

export default receptionServiceApi;
