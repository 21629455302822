import { Suspense, lazy } from "react";
import { Navigate, PartialRouteObject } from "react-router";

import { TopMenuLayout } from "./layout/TopMenuLayout";
import { LoadingScreen } from "./components/LoadingScreen";

import AuthGuard from "./components/Guards/AuthGuard";
import TopTabsMenu from "./components/templates/TopTabsMenu";
import { SettingsLayout } from "./layout/SettingsLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//Visit pages
const Visits = Loadable(
  lazy(() => import("./containers/visits").then((module) => ({ default: module.default })))
);

const CreateVisit = Loadable(
  lazy(() =>
    import("./containers/visits/create").then((module) => ({
      default: module.CreateVisitContainer,
    }))
  )
);

const CreateExpressQrPrinting = Loadable(
  lazy(() =>
    import("./containers/visits/create-express-qr-printing").then((module) => ({
      default: module.CreateExpressQrPrintingContainer,
    }))
  )
);

const VisitContainer = Loadable(
  lazy(() =>
    import("./containers/visits/[visitId]").then((module) => ({
      default: module.VisitContainer,
    }))
  )
);

const VisitsStatisticContainer = Loadable(
  lazy(() =>
    import("./containers/visits/statistics").then((module) => ({
      default: module.VisitsStatisticContainer,
    }))
  )
);

const Settings = Loadable(
  lazy(() =>
    import("./containers/settings").then((module) => ({ default: module.SettingsContainer }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <TopMenuLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <TopTabsMenu />,
        children: [
          {
            path: "/",
            element: <Navigate to="/visits" replace />,
          },
          {
            path: "visits",
            element: <Visits />,
          },
          {
            path: "visits/create",
            element: <CreateVisit />,
          },
          {
            path: "visits/express-create",
            element: <CreateExpressQrPrinting />,
          },
          {
            path: "visits/insights",
            element: <VisitsStatisticContainer />,
          },
          {
            path: "visits/:visitId",
            element: <VisitContainer />,
          },
        ],
      },
    ],
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <SettingsLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
