import { makeAutoObservable } from "mobx";

class MachineStore {
  constructor() {
    makeAutoObservable(this);
  }

  machineToken: string = null;

  isOpenDialog: boolean = false;

  setToken = (machineToken: string) => {
    this.machineToken = machineToken;
  };

  setIsOpenDialog = (isOpenDialog: boolean) => {
    this.isOpenDialog = isOpenDialog;
  };
}

export default MachineStore;
