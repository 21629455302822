import type { FC } from "react";

import { Dialog, DialogContent, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

import { Helpdesk } from "../../icons/signalApps/helpdesk";
import { AccessControl } from "../../icons/signalApps/accessControl";
import { Parking } from "../../icons/signalApps/parking";
import { ParkingSettings } from "../../icons/signalApps/withSettings/parkingSettings";

import { Elevators } from "../../icons/signalApps/elevators";
import { Visitors } from "../../icons/signalApps/visitors";
import { Hid } from "../../icons/signalApps/hid";

import { useSettings } from "../../contexts/settings-context";
import { VisitorsSettings } from "../../icons/signalApps/withSettings/visitorsSettings";
import { AccessControlSettings } from "../../icons/signalApps/withSettings/accessConstrolSettings";
import { HidSettings } from "../../icons/signalApps/withSettings/hidSettings";
import { SmartCity } from "../../icons/signalApps/smartCity";

interface AppDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AppDialog: FC<AppDialogProps> = (props) => {
  const { open, onClose, ...other } = props;

  const { settings } = useSettings();

  const applications = [
    {
      label: "Parking Administration",
      icon: <Parking theme={settings.theme} />,
      href: "https://parking-panel.vercel.app/",
    },
    {
      label: "Parking Site Administration",
      icon: <ParkingSettings theme={settings.theme} />,
      href: null,
    },
    {
      label: "Helpdesk Operator",
      icon: <Helpdesk theme={settings.theme} />,
      href: null,
    },
    {
      label: "Visitors Administration",
      icon: <Visitors theme={settings.theme} />,
      href: null,
    },
    {
      label: "Visitors Reception",
      icon: <Visitors theme={settings.theme} />,
      href: null,
    },
    {
      label: "Visitors Site Administration",
      icon: <VisitorsSettings theme={settings.theme} />,
      href: null,
    },
    {
      label: "Building self service",
      icon: <AccessControl theme={settings.theme} />,
      href: null,
    },
    {
      label: "Building self service - site admin",
      icon: <AccessControlSettings theme={settings.theme} />,
      href: null,
    },
    { label: "Elevators", icon: <Elevators theme={settings.theme} />, href: null },
    { label: "HID Administration", icon: <Hid theme={settings.theme} />, href: null },
    { label: "HID Site Administration", icon: <HidSettings theme={settings.theme} />, href: null },
    { label: "Smart city", icon: <SmartCity theme={settings.theme} />, href: null },
  ];

  return (
    <Dialog onClose={onClose} open={open} {...other}>
      <DialogContent sx={{ p: 2 }}>
        <Grid container columns={12} sx={{ maxWidth: 420 }}>
          {applications.map((app, i) => (
            <Grid
              item
              key={app.label}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: 1,
              }}
            >
              {app.href ? (
                // <a
                //   style={{ textDecoration: "none" }}
                //   key={i}
                //   href={app.href}
                //   target="_blank"
                //   rel="noreferrer"
                // >
                // </a>
                <IconButton
                  color="inherit"
                  onClick={() => {}}
                  sx={{
                    width: 75,
                    height: 75,
                  }}
                >
                  {app.icon}
                </IconButton>
              ) : (
                <Tooltip title="Dostępne wkrótce">
                  <IconButton
                    color="inherit"
                    onClick={() => {}}
                    sx={{
                      width: 75,
                      height: 75,
                    }}
                  >
                    {app.icon}
                  </IconButton>
                </Tooltip>
              )}
              <Typography sx={{ fontSize: "13px", fontWeight: 600, px: 2, textAlign: "center" }}>
                {app.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
